import React from "react"
import "../reset.css"
import "../global.css"
import "../fonts.css"
import Content from "./content.js"

export default function Home() {
  return (
    <div className="main">
      <div className="innerWrapper">
        <Content />
      </div>
    </div>
  )
}
